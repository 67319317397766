.errorContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .errorSection {
        align-self: center;

        h1 {
            font-size: 56px;
        }

        .link {
            color: #3b4f6a;
            font-weight: 600;
            cursor: pointer;
            user-select: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .errorText {
            padding-bottom: 30px;
        }

        p {
            padding-bottom: 15px;
        }
    }
}
