.addAdditionalServiceButton {
    border: none;
    background-color: transparent;
    margin-top: 4px;
}

.secondaryServiceSelect {
    margin-top: 8px;
}

.startDatePickerContainer {
    display: flex;
    justify-content: center;
}

.loader {
    height: 248px;
}

.link {
    color: #3b4f6a;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}

.link:hover {
    text-decoration: underline;
}
