.avatar {
    height: 42px;
    width: 42px;
}

.avatar :global(path) {
    fill: #3b4f6a;
}

.changeButton {
    margin-right: 12px;
}

.tableContainer {
    overflow-x: auto;
}

.changeButton,
.addPhotoButton {
    margin-bottom: 12px;
}