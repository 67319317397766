.content {
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
}

.calendar {
  flex: 1;
  min-height: 0;
}

.workerSelectContainer {
  flex: 1;
}

.overlayLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(255 255 255 / 70%);
  z-index: 100;
}

:global(.rbc-day-slot .rbc-event-content) {
  font-size: 90%;
}

.calendar :global(.rbc-row.rbc-time-header-cell .rbc-header:nth-child(6n)),
.calendar :global(.rbc-row.rbc-time-header-cell .rbc-header:nth-child(7n)),
.calendar
  :global(.rbc-time-header-content
    .rbc-allday-cell
    .rbc-row-bg
    .rbc-day-bg:nth-child(6n)),
.calendar
  :global(.rbc-time-header-content
    .rbc-allday-cell
    .rbc-row-bg
    .rbc-day-bg:nth-child(7n)),
.calendar
  :global(.rbc-month-view .rbc-row.rbc-month-header .rbc-header:nth-child(6)),
.calendar
  :global(.rbc-month-view .rbc-row.rbc-month-header .rbc-header:nth-child(7)),
.calendar
  :global(.rbc-month-view
    .rbc-month-row
    .rbc-row-bg
    .rbc-day-bg:nth-child(6):not(.rbc-off-range-bg)),
.calendar
  :global(.rbc-month-view
    .rbc-month-row
    .rbc-row-bg
    .rbc-day-bg:nth-child(7):not(.rbc-off-range-bg)) {
  background-color: rgb(128 128 128 / 8%);
}

.calendar :global(.rbc-row.rbc-time-header-cell .rbc-header:nth-child(6)),
.calendar :global(.rbc-row.rbc-time-header-cell .rbc-header:nth-child(7)),
.calendar
  :global(.rbc-month-view .rbc-row.rbc-month-header .rbc-header:nth-child(6)),
.calendar
  :global(.rbc-month-view .rbc-row.rbc-month-header .rbc-header:nth-child(7)),
.calendar.dayView.weekend :global(.rbc-toolbar-label) {
  color: #d40000;
}

.calendar.dayView.weekend
  :global(.rbc-time-header-content .rbc-allday-cell .rbc-day-bg.rbc-today),
.calendar.dayView.weekend :global(.rbc-time-content .rbc-day-slot.rbc-today) {
  background-color: rgb(234 246 255 / 35%);
}

.weekView :global(.rbc-event .rbc-event-label),
.dayView :global(.rbc-event .rbc-event-label) {
  display: none;
}

.eventSlotTimePrefix {
  font-size: 95%;
}

.eventSlotClientName {
  font-size: 80%;
  padding-top: 5px;
}

.inactiveSlot {
  background-color: #f5f5f5;
}

:global(.rbc-event) {
  white-space: pre-line;
}

.calendar.dayView:global(.rbc-allday-cell .rbc-row-bg .rbc-today) {
  background-color: initial;
}
