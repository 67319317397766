.tableContainer thead tr th:nth-child(1) {
  width: 70%;
}

.tableContainer thead tr th:nth-child(2),
.tableContainer thead tr th:nth-child(3),
.tableContainer thead tr th:nth-child(4) {
  width: 10%;
}

.categoryContainer:not(:first-child) {
  margin-top: 54px;
}

.categoryContainer:last-child {
  margin-bottom: 54px;
}

.categoryHeader {
  display: flex;
  gap: 16px;
  align-items: center;
}

.categoryDragIcon {
  margin-bottom: 8px;
}

.serviceRow {
  height: 60px;
}

@media (max-width: 768px) {
  .tableContainer thead tr th:nth-child(1) {
    width: 55%;
  }

  .tableContainer thead tr th:nth-child(2),
  .tableContainer thead tr th:nth-child(3),
  .tableContainer thead tr th:nth-child(4) {
    width: 15%;
  }
}
