.react-datepicker {
    border: 1px solid #ddd;
    font-family: "Open Sans", sans-serif;
}

.react-datepicker__header,
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-bottom: 1px solid #ddd;
}

.react-datepicker__time-container {
    border-left: 1px solid #ddd;
}

.react-datepicker__header {
    background-color: #3b4f6a;
}

.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker__day--today.react-datepicker__day--selected {
    color: white;
}

.react-datepicker__day--today {
    color: #3b4f6a;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #3b4f6a;
}

.react-datepicker__day--disabled.react-datepicker__day--keyboard-selected,
.react-datepicker__day--disabled.react-datepicker__month-text--keyboard-selected,
.react-datepicker__day--disabled.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__day--disabled.react-datepicker__year-text--keyboard-selected {
    background-color: white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: darken(#3b4f6a, 10%);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: white;
}

.react-datepicker__navigation:hover *::before {
    border-color: #ddd;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    &.react-datepicker__day--today {
        color: white;
    }
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

@media (max-width: 480px) {
    .react-datepicker {
        display: flex;
        flex-direction: column;

        .react-datepicker__time-container,
        .react-datepicker__time-container
            .react-datepicker__time
            .react-datepicker__time-box,
        .react-datepicker__time-container
            .react-datepicker__time
            .react-datepicker__time-box
            ul.react-datepicker__time-list {
            width: 100%;
        }

        .react-datepicker__time-container
            .react-datepicker__time
            .react-datepicker__time-box
            ul.react-datepicker__time-list
            li.react-datepicker__time-list-item:not(.react-datepicker__time-list-item--disabled) {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
            right: 0;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            width: 34px;
            line-height: 34px;
            font-size: 14px;
        }

        .react-datepicker__header.react-datepicker__header--time {
            border-top-left-radius: 0.3rem;
        }

        .react-datepicker__header.react-datepicker__header--has-time-select {
            border-top-right-radius: 0.3rem;
        }
    }
}
