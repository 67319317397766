.content {
    position: relative;
    display: flex;
    flex-direction: column;
}

.calendarContainer {
    position: relative;
    flex: 1;
    min-height: 0;
}

.calendarOverlayLoader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 10%);
    z-index: 40;
}

.overlayLoader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(255 255 255 / 70%);
    z-index: 100;
}

.calendar {
    :global {
        .rbc-time-header-cell {
            min-height: 35px;
        }

        .rbc-button-link {
            cursor: initial;
        }

        .rbc-allday-cell {
            display: none;
        }
    }
}

.copyButton {
    height: 8px;
    margin-left: 10px;
    cursor: pointer;
    width: 20px;
}

.calendar
    :global(.rbc-time-header-cell .rbc-header:not(.rbc-today):nth-child(6)),
.calendar
    :global(.rbc-time-header-cell .rbc-header:not(.rbc-today):nth-child(7)),
.calendar
    :global(.rbc-time-content .rbc-day-slot:not(.rbc-today)):nth-of-type(7),
.calendar
    :global(.rbc-time-content .rbc-day-slot:not(.rbc-today)):nth-of-type(8) {
    background-color: rgb(128 128 128 / 8%);
}

.calendar :global(.rbc-time-header-cell .rbc-header:nth-child(6)),
.calendar :global(.rbc-time-header-cell .rbc-header:nth-child(7)) {
    color: #d40000;
}
