.mainLayout {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.contentBody {
    height: calc(100vh - 56px);
    display: flex;
}

.sidebar {
    width: 280px;
}

.children {
    width: 100%;
}

.dropdownTrigger {
    cursor: pointer;
}

.dropdownTrigger::after {
    transform: rotate(180deg);
    margin-left: 0.5em;
}

.dropdownTrigger[aria-expanded="true"]::after {
    transform: initial;
}

.mobileName,
.mobileName:hover {
    color: white;
    text-decoration: none;
}

.burger {
    margin-right: 16px;
}

.mobileAside {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.avatar {
    height: 32px;
    width: 32px;
}

.avatar :global(path) {
    fill: #3b4f6a;
}

@media (min-width: 992px) {
    .children {
        overflow: auto;
    }

    .navList {
        display: none;
    }

    .burger {
        display: none;
    }

    .mobileName,
    .mobileOrganizationName {
        display: none;
    }
}

@media not screen and (min-width: 992px) {
    .sidebar {
        display: none !important;
    }
}
