.photo {
    border: 2px solid #3b4f6a;
    margin-bottom: 12px;
    width: 128px;
    height: 128px;
}

.placeholder {
    padding: 16px;
}

.placeholder :global(path) {
    fill: #3b4f6a;
}
